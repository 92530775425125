import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './registerServiceWorker'
import gtag from "vue-gtag-next";
import VueLazyLoad from 'vue3-lazyload'


const app = createApp(App);

app
    .use(gtag, { property: { id: "UA-186312148-1" } })
    .use(store)
    .use(router)
    .use(VueLazyLoad)
    .mount('#app');


    
