<template>
    <div class="banner">
        Get 15% OFF at checkout using 'RAMADAN15'
    </div>
    <div class="nav-container">
      <div id="nav" class="nav">
        <div @click="toggleMenu" class="burg">
            <svg id="burg" width="19" height="23" viewBox="0 0 32 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                <line y1="2" x2="32" y2="2"  stroke-width="3" stroke-linecap="round"/>
                <line y1="21" x2="32" y2="21"  stroke-width="3" stroke-linecap="round"/>
            </svg>
        </div>
        <div id="to-home">
           <router-link class="link" to="/">
              <svg id="textlogo" class="text-logo" width="178" height="21" viewBox="0 0 178 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M10.6871 19.245C10.3058 19.557 9.83778 19.791 9.28312 19.947C8.74578 20.103 8.17378 20.181 7.56712 20.181C6.04178 20.181 4.86312 19.7824 4.03112 18.985C3.19912 18.1877 2.78312 17.0264 2.78312 15.501V8.81903H0.495117V6.21903H2.78312V3.04703H6.03312V6.21903H9.75112V8.81903H6.03312V15.423C6.03312 16.099 6.19778 16.619 6.52712 16.983C6.85645 17.3297 7.33312 17.503 7.95712 17.503C8.68512 17.503 9.29178 17.3124 9.77712 16.931L10.6871 19.245Z" fill="black"/>
                <path d="M20.2246 5.95903C22.27 5.95903 23.83 6.45303 24.9046 7.44103C25.9966 8.4117 26.5426 9.88503 26.5426 11.861V19.999H23.4746V18.309C23.076 18.9157 22.504 19.3837 21.7586 19.713C21.0306 20.025 20.1466 20.181 19.1066 20.181C18.0666 20.181 17.1566 20.0077 16.3766 19.661C15.5966 19.297 14.99 18.803 14.5566 18.179C14.1406 17.5377 13.9326 16.8184 13.9326 16.021C13.9326 14.773 14.392 13.7764 15.3106 13.031C16.2466 12.2684 17.7113 11.887 19.7046 11.887H23.2926V11.679C23.2926 10.7084 22.998 9.96303 22.4086 9.44303C21.8366 8.92303 20.9786 8.66303 19.8346 8.66303C19.0546 8.66303 18.2833 8.78437 17.5206 9.02703C16.7753 9.2697 16.1426 9.6077 15.6226 10.041L14.3486 7.67503C15.0766 7.12036 15.952 6.6957 16.9746 6.40103C17.9973 6.10637 19.0806 5.95903 20.2246 5.95903ZM19.7826 17.815C20.5973 17.815 21.3166 17.633 21.9406 17.269C22.582 16.8877 23.0326 16.3504 23.2926 15.657V14.045H19.9386C18.0666 14.045 17.1306 14.6604 17.1306 15.891C17.1306 16.4804 17.3646 16.9484 17.8326 17.295C18.3006 17.6417 18.9506 17.815 19.7826 17.815Z" fill="black"/>
                <path d="M49.7976 5.95903C51.5483 5.95903 52.935 6.47037 53.9576 7.49303C54.9803 8.49837 55.4916 10.015 55.4916 12.043V19.999H52.2416V12.459C52.2416 11.2457 51.973 10.3357 51.4356 9.72903C50.8983 9.10503 50.127 8.79303 49.1216 8.79303C48.0296 8.79303 47.1543 9.15703 46.4956 9.88503C45.837 10.5957 45.5076 11.6184 45.5076 12.953V19.999H42.2576V12.459C42.2576 11.2457 41.989 10.3357 41.4516 9.72903C40.9143 9.10503 40.1429 8.79303 39.1376 8.79303C38.0283 8.79303 37.1443 9.14836 36.4856 9.85903C35.8443 10.5697 35.5236 11.601 35.5236 12.953V19.999H32.2736V6.11503H35.3676V7.88303C35.8876 7.25903 36.5376 6.78236 37.3176 6.45303C38.0976 6.1237 38.9643 5.95903 39.9176 5.95903C40.9576 5.95903 41.8763 6.15836 42.6736 6.55703C43.4883 6.93836 44.1296 7.51036 44.5976 8.27303C45.1696 7.54503 45.9063 6.9817 46.8076 6.58303C47.709 6.16703 48.7056 5.95903 49.7976 5.95903Z" fill="black"/>
                <path d="M64.2987 8.14303C65.2347 6.68703 66.8814 5.95903 69.2387 5.95903V9.05303C68.9614 9.00103 68.71 8.97503 68.4847 8.97503C67.2194 8.97503 66.2314 9.3477 65.5207 10.093C64.81 10.821 64.4547 11.8784 64.4547 13.265V19.999H61.2047V6.11503H64.2987V8.14303Z" fill="black"/>
                <path d="M87.0268 0.707031V19.999H83.9068V18.205C83.3694 18.8637 82.7021 19.3577 81.9048 19.687C81.1248 20.0164 80.2581 20.181 79.3048 20.181C77.9701 20.181 76.7654 19.8864 75.6908 19.297C74.6334 18.7077 73.8014 17.8757 73.1948 16.801C72.5881 15.709 72.2848 14.461 72.2848 13.057C72.2848 11.653 72.5881 10.4137 73.1948 9.33903C73.8014 8.26436 74.6334 7.43236 75.6908 6.84303C76.7654 6.2537 77.9701 5.95903 79.3048 5.95903C80.2234 5.95903 81.0641 6.11503 81.8268 6.42703C82.5894 6.73903 83.2394 7.20703 83.7768 7.83103V0.707031H87.0268ZM79.6948 17.399C80.4748 17.399 81.1768 17.2257 81.8008 16.879C82.4248 16.515 82.9188 16.0037 83.2828 15.345C83.6468 14.6864 83.8288 13.9237 83.8288 13.057C83.8288 12.1904 83.6468 11.4277 83.2828 10.769C82.9188 10.1104 82.4248 9.6077 81.8008 9.26103C81.1768 8.89703 80.4748 8.71503 79.6948 8.71503C78.9148 8.71503 78.2128 8.89703 77.5888 9.26103C76.9648 9.6077 76.4708 10.1104 76.1068 10.769C75.7428 11.4277 75.5608 12.1904 75.5608 13.057C75.5608 13.9237 75.7428 14.6864 76.1068 15.345C76.4708 16.0037 76.9648 16.515 77.5888 16.879C78.2128 17.2257 78.9148 17.399 79.6948 17.399Z" fill="black"/>
                <path d="M98.1424 5.95903C100.188 5.95903 101.748 6.45303 102.822 7.44103C103.914 8.4117 104.46 9.88503 104.46 11.861V19.999H101.392V18.309C100.994 18.9157 100.422 19.3837 99.6764 19.713C98.9484 20.025 98.0644 20.181 97.0244 20.181C95.9844 20.181 95.0744 20.0077 94.2944 19.661C93.5144 19.297 92.9077 18.803 92.4744 18.179C92.0584 17.5377 91.8504 16.8184 91.8504 16.021C91.8504 14.773 92.3097 13.7764 93.2284 13.031C94.1644 12.2684 95.629 11.887 97.6224 11.887H101.21V11.679C101.21 10.7084 100.916 9.96303 100.326 9.44303C99.7544 8.92303 98.8964 8.66303 97.7524 8.66303C96.9724 8.66303 96.2011 8.78437 95.4384 9.02703C94.6931 9.2697 94.0604 9.6077 93.5404 10.041L92.2664 7.67503C92.9944 7.12036 93.8697 6.6957 94.8924 6.40103C95.9151 6.10637 96.9984 5.95903 98.1424 5.95903ZM97.7004 17.815C98.5151 17.815 99.2344 17.633 99.8584 17.269C100.5 16.8877 100.95 16.3504 101.21 15.657V14.045H97.8564C95.9844 14.045 95.0484 14.6604 95.0484 15.891C95.0484 16.4804 95.2824 16.9484 95.7504 17.295C96.2184 17.6417 96.8684 17.815 97.7004 17.815Z" fill="black"/>
                <path d="M118.537 19.245C118.156 19.557 117.688 19.791 117.133 19.947C116.596 20.103 116.024 20.181 115.417 20.181C113.892 20.181 112.713 19.7824 111.881 18.985C111.049 18.1877 110.633 17.0264 110.633 15.501V8.81903H108.345V6.21903H110.633V3.04703H113.883V6.21903H117.601V8.81903H113.883V15.423C113.883 16.099 114.048 16.619 114.377 16.983C114.707 17.3297 115.183 17.503 115.807 17.503C116.535 17.503 117.142 17.3124 117.627 16.931L118.537 19.245Z" fill="black"/>
                <path d="M135.366 13.135C135.366 13.3604 135.349 13.681 135.314 14.097H124.42C124.611 15.1197 125.105 15.9344 125.902 16.541C126.717 17.1304 127.722 17.425 128.918 17.425C130.443 17.425 131.7 16.9224 132.688 15.917L134.43 17.919C133.806 18.6644 133.017 19.2277 132.064 19.609C131.111 19.9904 130.036 20.181 128.84 20.181C127.315 20.181 125.971 19.8777 124.81 19.271C123.649 18.6644 122.747 17.8237 122.106 16.749C121.482 15.657 121.17 14.4264 121.17 13.057C121.17 11.705 121.473 10.4917 122.08 9.41703C122.704 8.32503 123.562 7.4757 124.654 6.86903C125.746 6.26237 126.977 5.95903 128.346 5.95903C129.698 5.95903 130.903 6.26237 131.96 6.86903C133.035 7.45837 133.867 8.29903 134.456 9.39103C135.063 10.4657 135.366 11.7137 135.366 13.135ZM128.346 8.55903C127.306 8.55903 126.422 8.87103 125.694 9.49503C124.983 10.1017 124.55 10.9164 124.394 11.939H132.272C132.133 10.9337 131.709 10.119 130.998 9.49503C130.287 8.87103 129.403 8.55903 128.346 8.55903Z" fill="black"/>
                <path d="M141.088 20.181C140.498 20.181 139.996 19.9817 139.58 19.583C139.164 19.167 138.956 18.647 138.956 18.023C138.956 17.3817 139.155 16.8617 139.554 16.463C139.97 16.0644 140.481 15.865 141.088 15.865C141.694 15.865 142.197 16.0644 142.596 16.463C143.012 16.8617 143.22 17.3817 143.22 18.023C143.22 18.647 143.012 19.167 142.596 19.583C142.18 19.9817 141.677 20.181 141.088 20.181Z" fill="black"/>
                <path d="M154.071 20.181C152.633 20.181 151.341 19.8777 150.197 19.271C149.053 18.6644 148.161 17.8237 147.519 16.749C146.878 15.657 146.557 14.4264 146.557 13.057C146.557 11.6877 146.878 10.4657 147.519 9.39103C148.161 8.31637 149.045 7.4757 150.171 6.86903C151.315 6.26237 152.615 5.95903 154.071 5.95903C155.441 5.95903 156.637 6.23637 157.659 6.79103C158.699 7.3457 159.479 8.14303 159.999 9.18303L157.503 10.639C157.105 9.9977 156.602 9.52103 155.995 9.20903C155.406 8.8797 154.756 8.71503 154.045 8.71503C152.832 8.71503 151.827 9.1137 151.029 9.91103C150.232 10.691 149.833 11.7397 149.833 13.057C149.833 14.3744 150.223 15.4317 151.003 16.229C151.801 17.009 152.815 17.399 154.045 17.399C154.756 17.399 155.406 17.243 155.995 16.931C156.602 16.6017 157.105 16.1164 157.503 15.475L159.999 16.931C159.462 17.971 158.673 18.777 157.633 19.349C156.611 19.9037 155.423 20.181 154.071 20.181Z" fill="black"/>
                <path d="M170.406 20.181C169.002 20.181 167.736 19.8777 166.61 19.271C165.483 18.6644 164.599 17.8237 163.958 16.749C163.334 15.657 163.022 14.4264 163.022 13.057C163.022 11.6877 163.334 10.4657 163.958 9.39103C164.599 8.31637 165.483 7.4757 166.61 6.86903C167.736 6.26237 169.002 5.95903 170.406 5.95903C171.827 5.95903 173.101 6.26237 174.228 6.86903C175.354 7.4757 176.23 8.31637 176.854 9.39103C177.495 10.4657 177.816 11.6877 177.816 13.057C177.816 14.4264 177.495 15.657 176.854 16.749C176.23 17.8237 175.354 18.6644 174.228 19.271C173.101 19.8777 171.827 20.181 170.406 20.181ZM170.406 17.399C171.602 17.399 172.59 17.0004 173.37 16.203C174.15 15.4057 174.54 14.357 174.54 13.057C174.54 11.757 174.15 10.7084 173.37 9.91103C172.59 9.1137 171.602 8.71503 170.406 8.71503C169.21 8.71503 168.222 9.1137 167.442 9.91103C166.679 10.7084 166.298 11.757 166.298 13.057C166.298 14.357 166.679 15.4057 167.442 16.203C168.222 17.0004 169.21 17.399 170.406 17.399Z" fill="black"/>
            </svg>

           </router-link>
        </div>
        <router-link to="/bag">
            <svg id="bag" class="bag" width="33" height="34" viewBox="0 0 33 34" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path id="bag-pouch" d="M2.76194 13.1943C2.91696 10.9959 4.7456 9.29167 6.94946 9.29167H26.0505C28.2544 9.29167 30.083 10.9959 30.2381 13.1943L31.2972 28.2151C31.4685 30.6438 29.5444 32.7083 27.1097 32.7083H5.8903C3.45559 32.7083 1.53153 30.6438 1.70278 28.2151L2.76194 13.1943Z" stroke="black" stroke-width="2.58333" stroke-linejoin="round"/>
                <path id="bag-handle" d="M9.00331 13C8.92138 9.33333 10.3306 2 16.6228 2M23.9967 13C24.0786 9.33333 22.6694 2 16.3772 2" stroke="black" stroke-width="2.58333" stroke-linecap="round"/>
                <circle id="dot" class="showdot" stroke="none" cx="16.5" cy="21" r="5" fill="#C29A5C" display="none"/>
            </svg>
        </router-link>
      </div>
      <div id="menu-container" class="menu-container">
        <div id="menu" class="menu">
            <router-link @click="toggleMenu" class="link" to="/">HOME</router-link>
            <router-link @click="toggleMenu" class="link" to="/">OUR STORY</router-link> 
            <router-link @click="toggleMenu" class="link" to="/Products">SHOP</router-link> 
            <router-link @click="toggleMenu" class="link" to="/Contact">CONTACT</router-link> 
        </div>
      </div>
    </div>

    
    <transition name="fade" mode="out-in">
        <router-view/>
    </transition>
  <footer>
    <div class="footer-container">
        <img class="date" src="/Assets/tamr-logo-gold.svg" alt="">
        <div class="quick-links">
            <router-link to="/delivery-returns" class="ql">Delivery</router-link>
            <router-link to="/delivery-returns" class="ql">Returns</router-link>
            <router-link to="/" class="ql">Payments</router-link>
            <router-link to="/" class="ql">FAQs</router-link>
            <router-link to="/Contact" class="ql">Contact</router-link>
        </div>
        <a id="ig-anchor" href="https://www.instagram.com/tamrdate.co/">
            <img src="/Assets/instagram.svg" id="ig-link" alt="">
        </a>
        <img class="payment-ways" src="/Assets/payment-ways.svg" alt="">
        <div class="copyright">&#169; tamrdate.co</div>
    </div>
    
  </footer>
</template>

<script>
export default {
  data: function () {
    return {
      showNav: ''
    }
  },
  methods: {
    toggleMenu()
    {
      var menu = document.getElementById('menu-container');
      var links = document.getElementById('menu');

      if(window.pageYOffset < 300 && this.$route.name == 'Home')
      {
        document.getElementById('burg').classList.toggle('white');
        document.getElementById('bag-pouch').classList.toggle('white');
        document.getElementById('bag-handle').classList.toggle('white');
      }

      if(!this.showNav)
      {
          setTimeout(function() {
          menu.classList.add('show-menu');
          links.classList.add('show-links');
          }, 25);
      }
      else
      {
          setTimeout(function() {
          menu.classList.remove('show-menu');
          links.classList.remove('show-links');
          }, 25);
      }

      this.showNav = !this.showNav;
    },
    showDot()
    {
        let products = [];

        if(localStorage.getItem('products'))
        {
            products = JSON.parse(localStorage.getItem('products'));
        }

        if (products.length > 0) 
        {
            document.getElementById('dot').classList.add('showdot');
        }
        else
        {
            document.getElementById('dot').classList.remove('showdot');
        }
    },
    
  },
  mounted()
  {
    this.showDot();
  },
}
</script>


<style>


.banner {
    position: relative;
    background: rgba(238, 48, 48, 0.89);
    width: 100%;
    height: 40pt;
    margin-top: 37.5pt;
    left: 0;
    z-index: 9999;
    color: white;
    text-align: center;
    line-height: 40pt;
    font-weight: 500;
    font-size: 10.5pt;
}

@import url('https://fonts.googleapis.com/css2?family=Inter:wght@200;300;400;500;600;700&family=Montserrat:ital,wght@0,300;0,500;0,600;1,500&display=swap');

.container
{
    min-height: 57vh;
}


::-webkit-scrollbar {
  display: none;
}

body
{
    margin: auto;
    scroll-behavior: smooth;
    font-family: 'Inter';
    font-weight: 400;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -webkit-tap-highlight-color: transparent;
}

a
{
    text-decoration: none;
    color: unset;
}

button
{
    border: unset;
}


img
{
    display: block;
    margin: auto;
}

h1
{
    font-family: 'Montserrat';
    text-transform: uppercase;
    padding-left: 10pt;
    padding-bottom: 10pt;
    font-size: 1.4em;
    letter-spacing: 0.05em;
    color: rgb(24, 24, 24);
}

#app {
  font-family: 'Inter', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #222222;
  box-sizing: content-box;
  scroll-behavior: smooth;
}

.nav a.router-link-exact-active {
  color: #42b983;
}

.nav-container
{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    max-width: 1400pt;
    z-index: 999999;
    left: 50%;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
    height: fit-content;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
}

.transparent-nav
{
    background-color: unset !important;
    -webkit-backdrop-filter: unset !important;
    backdrop-filter: unset !important;
    border-bottom: none  !important;
    transition: all ease-in-out 300ms;
}


.nav
{

    background-color: white;
    width: 100%;

    z-index: 999999;

    display: flex;
    justify-content: space-between;
    border-bottom: 0.5pt solid rgb(230, 230, 230);
    transition: all ease-in-out 300ms;
}

.burg
{
    stroke:black;
    padding-left: 13pt;
    padding-right: 13pt;
    padding-top: 10pt;
    padding-bottom: 6pt;
    cursor: pointer;
}

.white
{
    fill: white !important;
    stroke: white !important;
}



.bag
{
    stroke: black;
    height: 15pt;
    padding-left: 8pt;
    padding-right: 6pt;
    padding-top: 10pt;
    padding-bottom: 9pt;
}

#to-home
{
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    padding-top: 3.5pt;
    padding-bottom: 8pt;
    padding-left: 7pt;
    padding-right: 9pt;
}

.text-logo
{
    margin: auto;
    max-height: 9pt;
    padding-bottom: 0.5pt;
}

.date
{
    margin-top: 6.5pt;
    height: 15pt;
}

.menu-container
{
    position: fixed;
    height: 0pt;
    transition: all ease-in-out 250ms;
    width: 200pt;
    height: 20pt;
    border-radius: 10vw;
    max-width: 1400pt;
    visibility: hidden;
}

.show-menu
{
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;
    background: white;
    border-radius: 0;
    visibility: visible;
}


.menu
{
    width: 100%;
    margin: auto;
    padding: 0;
    padding-left: 25pt;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    visibility: hidden;
    opacity: 0;
    transition: all ease-in-out 200ms 0ms;
    margin-top: 50pt;
    z-index: -1;
}

.show-links
{
    width: 100%;
    opacity: 1;
    visibility: visible;
    transition: all ease-in-out 300ms 50ms;
    padding-bottom: 10pt;
    padding-top: 10pt;
}



.link
{
    flex: 1;
    font-family: 'Montserrat';
    font-weight: 600 !important;
    font-size: 1.6em;
    letter-spacing: 1pt;
    color: black;
    min-height: 30pt;
    text-decoration: none;
    margin-bottom: 20pt;
}


.showdot
{
    display: block;
    fill:#F2BA2A !important;
}


.quick-links
{
    display: flex;
    flex-direction: column;
    padding-bottom: 35pt;
    color: rgb(24, 24, 24);
}

.ql
{
    padding: 5pt;
    padding-bottom: 10pt;
    font-size: 1em;
    font-weight: 600;
}


#ig-link
{
    max-width: 30pt;
    margin-top: 0pt;
    margin-bottom: 30pt;
    margin-left: 5pt;
    height: 22pt;
}



.payment-ways
{
    margin: auto;
    width: 100%;
    max-width: 240pt;
    padding-left: 6pt;
    padding-bottom: 20pt;
}

footer
{
    position: absolute;
    width: 100%;
    padding: 20pt;
    padding-top: 30pt;
    background-color: rgb(252, 252, 252);
    display: flex;
    flex-direction: column;
    font-family: 'Montserrat';
    font-size: 0.9em;
    z-index: 99999;
    left: 0;
    right: 0;
    box-sizing: border-box;
}

.footer-container
{
    margin: auto;
    z-index: 99999;
    max-width: 900pt;
    width: 100%;
}

footer .date
{
    margin-left: 0;
    padding: 5pt;
    height: 50pt;
    padding-bottom: 30pt;
}

.copyright
{
    margin: auto;
    font-family: 'Inter';
    text-align: center;
    color: #949494;
}
</style>
